<template>

  <validation-observer ref="simpleRules">
    <b-form
      @submit.prevent="postCategoryData"
      @reset.prevent="OnReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Name *"
            label-for="name"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="name"
            >
              <b-form-input
                id="name"
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Slug *"
            label-for="slug"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="slug"
            >
              <b-form-input
                id="slug"
                v-model="formData.slug"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Slug"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref } from '@vue/composition-api'
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    // vSelect,
    // quillEditor,
    // BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    //   categoryData: [1, 2, 3],
      formData: {},
      required,
      userData: {},
    }
  },
  mounted() {
    this.getUser()
    // this.getCategoryData()
  },
  methods: {
    // onFileChanged(event) {
    //   // eslint-disable-next-line prefer-destructuring
    //   this.formData.featured_image = event.target.files[0]
    // },
    getUser() {
      const userLocalStorage = localStorage.getItem('user')
      const userDecoded = Buffer.from(userLocalStorage, 'base64').toString('utf-8')
      const userParsed = JSON.parse(userDecoded)
      this.userData = userParsed
    },
    postCategoryData() {
      this.$refs.simpleRules.validate().then(success => {
        const postData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.formData)) {
          postData.append(key, value)
        }

        // eslint-disable-next-line no-restricted-syntax
        // for (const pair of postData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }
        // postData.append('created_by', this.userData.id === undefined ? '' : this.userData.id)
        // postData.append('category_id', 1)
        // postData.delete('category')

        if (success) {
          this.$swal({
            title: 'Please Wait..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          }).then(
            axios.post('/categories', postData).then(res => {
              this.form = {}
              // eslint-disable-next-line no-console
              console.log('res', res)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Your data has been saved.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'category-all' })
              })
            }),
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.2rem;
    margin-bottom: 2px;
    padding-bottom: 10px;
    height: 150px;
}

.ql-container{
  min-height: inherit;
}
</style>
